<template>
  <v-card>
    <v-card-title>{{ `${$t('menu.configuration')} /  ${$t('landing.evento')} ${evento.id ? ` / ${evento.title}` : ''}` }}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="2"
        >
          <v-btn
            color="error"
            class="mb-4 me-3"
            @click="
              $router.push({
                name: 'events-citas',
                params: {
                  slug: $route.params.slug,
                },
              })
            "
          >
            <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
            <span>{{ $t('btn.back') }}</span>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-btn
            v-if="!$store.state.app.onlyShow"
            color="primary"
            class="mb-4 me-3"
            :loading="loading"
            @click="send()"
          >
            <span>{{ $t('btn.send') }}</span>
            <v-icon>{{ icons.mdiUpdate }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-form
        class="multi-col-validation"
      >
        <v-row
          class="match-height"
        >
          <v-col
            cols="12"
            md="8"
          >
            <v-card
              color="text-center"
            >
              <v-card-text>
                <v-form
                  class="pb-6 pt-2"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-select
                        v-model="model.date"
                        :items="datesFree"
                        :label="`${$t('landing.eventSoli.date')} *`"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="textRequiered"
                        @change="setDate"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              {{ $t('landing.eventSoli.notTimes') }}
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-select
                        v-model="model.time"
                        :items="times"
                        :label="`${$t('landing.eventSoli.time')} *`"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="textRequiered"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              {{ $t('landing.eventSoli.notTimes') }}
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="model.name"
                        :label="`${$t('landing.eventSoli.fullname')} *`"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="textRequiered"
                        :placeholder="$t('landing.eventSoli.names')"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="model.email"
                        label="Email *"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="emailRules"
                        placeholder="Email"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="model.company"
                        :label="`${$t('landing.eventSoli.company')}`"
                        outlined
                        dense
                        hide-details="auto"
                        :placeholder="$t('landing.eventSoli.yourCompany')"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="model.rol"
                        :label="`${$t('landing.eventSoli.rol')}`"
                        outlined
                        dense
                        hide-details="auto"
                        :placeholder="$t('landing.eventSoli.yourRol')"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="model.site_web"
                        :label="$t('landing.eventSoli.sitioWeb')"
                        outlined
                        dense
                        hide-details="auto"
                        :placeholder="$t('landing.eventSoli.subSitioWeb')"
                        clearable
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <v-text-field
                        v-model="model.code_country"
                        :label="$t('register.codePais')"
                        outlined
                        dense
                        hide-details="auto"
                        :placeholder="$t('register.codePais')"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="model.phone"
                        :label="`${$t('register.noTelefo')}`"
                        outlined
                        dense
                        hide-details="auto"
                        :placeholder="$t('register.noTelefo')"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-textarea
                        v-model="model.comentarios"
                        :label="`${$t('landing.eventSoli.comentarios')}`"
                        :placeholder="`${$t('landing.eventSoli.subComentarios')}`"
                        outlined
                        dense
                        rows="2"
                        hide-details="auto"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            md="4"
            sm="12"
            cols="12"
            class="kb-search-content"
          >
            <v-card
              color="text-center"
            >
              <v-card-text class="justify-center">
                <v-img
                  height="100px"
                  contain
                  :src="img"
                ></v-img>
              </v-card-text>
              <v-card-text>
                <v-divider></v-divider>
              </v-card-text>
              <v-card-text>
                <div
                  v-if="evento.country"
                  class="d-flex align-center"
                >
                  <v-icon
                    size="20"
                  >
                    {{ icons.mdiMapMarkerOutline }}
                  </v-icon>
                  <div class="ms-2">
                    <p class="text--primary mb-0">
                      {{ `${evento.city}, ${evento.country}` }}
                    </p>
                    <!--<span class="text-xs">{{ data.subtitle }}</span>-->
                  </div>
                </div>
                <div
                  class="d-flex align-center mt-2"
                >
                  <v-icon
                    size="20"
                  >
                    {{ icons.mdiClockOutline }}
                  </v-icon>
                  <div class="ms-2">
                    <p class="text--primary mb-0">
                      {{ $moment(evento.dateFrom).locale($i18n.locale).format('dddd, D MMMM') }}
                      -
                      {{ $moment(evento.dateTo).locale($i18n.locale).format('dddd, D MMMM') }}
                    </p>
                    <!--<span class="text-xs">{{ data.subtitle }}</span>-->
                  </div>
                </div>
                <div
                  v-if="evento.pabellon || evento.stand"
                  class="d-flex align-center mt-2"
                >
                  <v-icon
                    size="20"
                  >
                    mdi-location-enter
                  </v-icon>
                  <div class="ms-2">
                    <p class="text--primary mb-0">
                      <template v-if="evento.pabellon">
                        {{ $t('landing.eventoPabellon') }} {{ evento.pabellon }}
                      </template>
                      <span v-if="evento.pabellon && evento.stand">-</span>
                      <template v-if="evento.stand">
                        {{ $t('landing.eventoStand') }} {{ evento.stand }}
                      </template>
                    </p>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <Loading
      v-if="isLoading"
      :full-page="true"
      :color="$vuetify.theme.themes.light.primary"
    />
  </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

// import { v4 as uuidv4 } from 'uuid'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiInformation,
  mdiMenuDown,
  mdiMenuRight,
  mdiDotsVertical,
} from '@mdi/js'
// eslint-disable-next-line no-unused-vars, import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'

// import SidebarDataSocio from '../utils/SidebarDataSocio.vue'

export default {
  components: {
    Loading,

    // SidebarDataSocio,
  },
  data() {
    return {
      urlS3: process.env.VUE_APP_API_URL_S3_HOTEL,
      showRowSocios: false,
      isSidebarDataSocioActive: false,
      isLoading: true,
      isDialogBloqueo: false,
      loading: false,
      socioId: null,
      evento: {
        id: null,
        dateFrom: null,
        dateTo: null,
        socios: [],
      },
      socio: {},
      agendarOps: [],
      socios: [],
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiInformation,
        mdiMenuDown,
        mdiMenuRight,
        mdiDotsVertical,
      },
      model: {},
      id_model: -1,
      permisos: localStorage.getItem('permisos_auth'),
      tipos_fiscal: [],
      tipos_cuentas_bancarias: [],
      monedas: [],
      catalogs: [],
      countries: [],
      qr: null,
      existeQR: false,
      imgs: [],
      img: null,
      logo: null,
      pdf: null,
      menuFrom: false,
      menuTo: false,
      pdf_catalog: false,
      isDialogSocio: false,
      imgEdit: {},
      showImg: false,
      minDate: null,
      dayFree: [],
      datesFree: [],
      times: [],
      confirmadaBefore: false,
      textRequiered: [v => !!v || this.$t('landing.rule1')],
      emailRules: [v => !!v || this.$t('landing.rule1'), v => this.isEmail(v) || this.$t('landing.rule2')],
      urlRules: [v => this.isURL(v) || this.$t('landing.rule3')],
      phoneRules: [v => !!v || this.$t('landing.rule1'), v => Number.isInteger(Number(v)) || this.$t('landing.rule4')],
    }
  },
  computed: {
    ...mapState({
      emailsReserve: state => state.app.emailsReserve,
      ctasBancarias: state => state.app.ctasBancarias,
      dataBancaria: state => state.app.dataBancaria,
      posCotizador: state => state.app.posCotizador,
    }),
    computedFromDateFormatted() {
      return this.evento.dateFrom ? this.$moment(this.evento.dateFrom).locale(this.$i18n.locale).format('D-MMM-YY') : ''
    },
    computedDateToFormatted() {
      return this.evento.dateTo ? this.$moment(this.evento.dateTo).locale(this.$i18n.locale).format('D-MMM-YY') : ''
    },
  },
  created() {
    // this.getCodeCountry()
    this.getEvent()
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/events/event1.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/events/event2.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/events/event3.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/events/event4.svg'))
  },
  methods: {
    ...mapMutations([
      'addEmailAdminReserve',
      'updateEmailAdminReserve',
      'addCtasBancarias',
      'updateCtasBancarias',
      'setPosCotizador',
      'updateDataBancaria',
    ]),
    getTitle(item) {
      if (this.$i18n.locale === 'es') {
        return item.title
      }

      return item[`title_${this.$i18n.locale}`]
    },
    getDescripcion(item) {
      if (this.$i18n.locale === 'es') {
        return item.description
      }

      return item[`description_${this.$i18n.locale}`]
    },
    isURL(str) {
      let url

      try {
        if (str === '' || str === null || str === undefined) {
          return true
        }
        url = new URL(str)
      } catch (_) {
        return false
      }

      return url.protocol === 'http:' || url.protocol === 'https:'
    },
    isEmail(str) {
      const res = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      try {
        if (str === '' || str === null || str === undefined) {
          return true
        }
        if (res.test(str)) {
          return true
        }

        return false
      } catch (_) {
        return false
      }
    },
    autocompletarWeb() {
      if (this.model.site_web) {
        if (this.model.site_web.length === 1) {
          this.model.site_web = `https://${this.model.site_web}`
        }
      }
    },
    async getCodeCountry() {
      await this.axios
        .get('https://ipapi.co/json')
        .then(res => {
          this.model.code_country = res.data.country_calling_code
        })
    },
    setDate() {
      this.model.time = null
      if (this.dayFree.filter(e => e.date === this.model.date).length > 0) {
        this.times = this.dayFree.filter(e => e.date === this.model.date)[0].times
      } else {
        this.times = []
      }
    },
    shuffleDeck() {
      const numbers = [...this.imgs]
      let first
      let second
      let temp
      const count = numbers.length
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        first = Math.floor(Math.random() * count)
        second = Math.floor(Math.random() * count)
        temp = numbers[first]
        numbers[first] = numbers[second]
        numbers[second] = temp
      }
      this.imgs = numbers
      if (this.evento.logo) {
        this.img = this.urlS3 + this.evento.logo
      } else {
        // eslint-disable-next-line prefer-destructuring
        this.img = this.imgs[0]
      }
    },
    async getEvent() {
      let uiid = null
      if (localStorage.getItem('event-cita-id') !== null) {
        uiid = localStorage.getItem('event-cita-id')
      }
      const json = {
        id: localStorage.getItem('event-id'),
        uiid,
      }
      await this.axios
        .post('events/show-event', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {} else {
            this.evento = res.data.data.item
            if (res.data.data.cita !== '') {
              this.model = res.data.data.cita
              this.times.push(this.model.time)
            }

            this.dayFree = this.evento.daysFree
            this.dayFree.forEach(element => {
              this.datesFree.push(element.date)
            })

            if (this.dayFree.filter(e => e.date === this.model.date).length > 0) {
              const ttt = this.dayFree.filter(e => e.date === this.model.date)[0].times
              ttt.forEach(element => {
                this.times.push(element)
              })
            }
          }
        })
        .finally(() => {
          this.isLoading = false
          this.shuffleDeck()
        })
    },
    getQR() {
      this.axios
        .post('catalogs/qr-event', { slug: this.$route.params.slug })
        .then(res => {
          this.qr = res.data
        })
    },

    send() {
      if (this.model.name
        && this.model.email
        && this.model.date
        && this.model.time
      ) {
        this.loading = true

        this.model.slug = this.$route.params.slug
        this.model.leng = this.$i18n.locale

        if (localStorage.getItem('event-cita-id') === null) {
          this.axios
            .post('events/solicitar-cita-gestor', this.model, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(res => {
              if (res.data.success) {
                this.$toast.success(this.$t('msg.infoSuccess'))
                this.$router.push({
                  name: 'events-citas',
                  params: {
                    slug: this.$route.params.slug,
                  },
                })
              } else {
                const arr = []
                this.times.forEach(element => {
                  if (element !== this.model.time) {
                    arr.push(element)
                  }
                })
                this.model.time = null
                this.times = arr
                this.$toast.error(this.$t('landing.event.h2'))
              }
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          this.axios
            .post('events/update-cita-gestor', this.model, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(res => {
              if (res.data.success) {
                this.$toast.success(this.$t('msg.infoSuccess'))
                this.$router.push({
                  name: 'events-citas',
                  params: {
                    slug: this.$route.params.slug,
                  },
                })
              } else {
                const arr = []
                this.times.forEach(element => {
                  if (element !== this.model.time) {
                    arr.push(element)
                  }
                })
                this.model.time = null
                this.times = arr
                this.$toast.error(this.$t('landing.event.h2'))
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    updateSocios() {
      let existe = false
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.evento.socios.length; index++) {
        const element = this.evento.socios[index]
        if (element.id === this.socioId) {
          existe = true
          this.evento.socios[index] = this.ssocioId
        }
      }

      if (!existe) {
        this.evento.socios.push(this.socioId)
      }
      this.isDialogSocio = false
      this.socioId = null
    },
  },
}
</script>
<style>
/**PARA OCULTAR LA FECHA DE HOY EN EL COMPONENTE DE HORA */
.vc-date-time .vc-date .vc-weekday,
.vc-date-time .vc-date .vc-month,
.vc-date-time .vc-date .vc-day,
.vc-date-time .vc-date .vc-year {
  display: none;
}
</style>
